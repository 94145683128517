import React from "react";
import { GraphQLFormattedError } from 'graphql';

export default function GraphQLError(
    props: {
      error: ReadonlyArray<GraphQLFormattedError>
    }) {


  return <div>Error! ${props.error?.map(err => err.message)}</div>;

}
